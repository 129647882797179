import * as React from 'react';
import { Menu, MenuItemLink } from 'react-admin';


export const CustomMenu = props => (
    <Menu {...props}>
        <MenuItemLink to="https://beecars.ru" primaryText="BeeCars" onClick={() => window.location.href = 'https://beecars.ru'} />
        <MenuItemLink to="/price-ladder" primaryText="Price ladder по комплектациям" />
        <MenuItemLink to="/market-structure" primaryText="Price ladder по моделям" />
    </Menu>
);