import React, {useEffect, useCallback} from 'react';
import Box from "@mui/material/Box";
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    LabelList,
} from 'recharts';
import {useCreate, LoadingIndicator, useDataProvider} from 'react-admin';
import {getCleanHydraId} from "../../utils/HydraUtils";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useCurrentPng} from "recharts-to-png";
import SaveIcon from '@mui/icons-material/Save';

const CustomTooltip = (props) => {
    const {active, payload, label, awp} = props;

    if (active && payload && payload.length) {
        return (
            <Card variant="outlined">
                <CardContent>
                    <Typography sx={{mb: 1.5}}>
                        <strong>Модель:</strong>{` ${payload[0].payload.model}`}
                    </Typography>
                    <Typography sx={{mb: 1.5}}>
                        <strong>Цена:</strong>{` ${(awp ? payload[0].payload.awp : payload[0].payload.price).toLocaleString('ru', {
                        style: 'currency',
                        currency: 'RUB',
                        maximumFractionDigits: 0
                    })} `}
                    </Typography>
                    <Typography sx={{mb: 1.5}}>
                        <strong>Продажи:</strong>{` ${(payload[0].payload.sales).toLocaleString('ru', {
                        style: 'decimal',
                        maximumFractionDigits: 0
                    })} шт.`}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    return null;
};

const CustomizedLabel = (props) => {
    const {x, y, fill, value} = props;

    return <text
        x={x}
        y={y}
        dy={-4}
        fontSize='12'
        fontFamily='sans-serif'
        fill={fill}
        textAnchor="end">{value}</text>
};

export const ChartMarketStructure = (props) => {
    const {options, from, to, awp} = props;
    const [create, {data, isLoading, error}] = useCreate();
    const dataProvider = useDataProvider();
    const [createXslx, {dataXslx, isLoadingXslx, errorXslx}] = useCreate();

    const [getPng, {ref: chartRef}] = useCurrentPng();

    /*
    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            FileSaver.saveAs(png, 'market-structure.png');
        }
    }, [getPng]);
     */
    const handleDownload = () => {
        dataProvider.create('reports/market-structure', {
            data: {
                from: from,
                to: to,
                type: 'xslx',
                options: options.map(o => {
                    return {
                        brand: parseInt(getCleanHydraId(o.brand.id)),
                        model: parseInt(getCleanHydraId(o.model.id)),
                        engine: o.engine?.id,
                        drive: o.drive?.id,
                        versions: Array.isArray(o.versions) ? o.versions.map(v => parseInt(getCleanHydraId(v.id))) : []
                    }
                })
            }
        }).then(({data}) => {
            const base64 = JSON.parse(data.result);
            download(base64, 'price-ladder-model.xlsx')
        })
    }

    function download(content, fileName) {
        var link = document.createElement("a");
        link.href = content;
        link.download = fileName;
        link.click();
    }

    useEffect(() => {
        create('reports/market-structure', {
            data: {
                from: from,
                to: to,
                options: options.map(o => {
                    return {
                        brand: parseInt(getCleanHydraId(o.brand.id)),
                        model: parseInt(getCleanHydraId(o.model.id)),
                        engine: o.engine?.id,
                        drive: o.drive?.id,
                        versions: Array.isArray(o.versions) ? o.versions.map(v => parseInt(getCleanHydraId(v.id))) : []
                    }
                })
            }
        })
    }, [from, to, options.length]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    if (undefined === data) {
        return <div/>
    }

    return (
        <React.Fragment>
            {(data.result.length > 0) ?
                <Box sx={{width: '80%', height: '500', marginTop: '15px', paddingBottom: '15px'}}>
                    <Button startIcon={<SaveIcon/>} onClick={handleDownload}>Сохранить график</Button>
                    <ScatterChart
                        ref={chartRef}
                        width={1200}
                        height={500}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid/>
                        <XAxis type="number" dataKey="length" name="Длина" unit='мм' domain={['dataMin - 100', 'dataMax + 100']}/>
                        <YAxis type="number" dataKey={awp ? 'awp' : 'price'} name="Цена" tickFormatter={
                            (value) => value.toLocaleString('ru', {
                                style: 'currency',
                                currency: 'RUB',
                                maximumFractionDigits: 0
                            })
                        } width={90} domain={['dataMin - 500000', 'dataMax + 100000']}/>
                        <ZAxis type="number" dataKey="weight" range={[40, 800]} name="Объем продаж" unit="шт"/>
                        <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip awp={awp}/>}/>
                        {
                            data.result.map((d, index) => {
                                if (awp && d.awp === 0) {
                                    return null;
                                }
                                return (
                                    <Scatter key={`scatter${index}`} data={d} fill='#5B9BD5'>
                                        <LabelList dataKey="model" position="right" content={<CustomizedLabel/>}/>
                                    </Scatter>
                                )
                            })
                        }
                    </ScatterChart>
                </Box> : <div/>
            }


            {(data.result.length > 0) ?
                <Box sx={{width: '80%', marginTop: '15px', paddingBottom: '15px'}}>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Модель</TableCell>
                                    <TableCell align="right">Продажи (шт)</TableCell>
                                    <TableCell align="right">{awp ? 'Средневзвешанная цена модели' : 'Средняя цена модели'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.result.map((d) => (
                                    <TableRow
                                        key={d[0].model}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            {d[0].model}
                                        </TableCell>
                                        <TableCell align="right">{d[0].sales}</TableCell>
                                        {awp ? <TableCell align="right">{d[0].awp.toLocaleString('ru', {
                                            style: 'currency',
                                            currency: 'RUB',
                                            maximumFractionDigits: 0
                                        })}</TableCell> : <TableCell align="right">{d[0].price.toLocaleString('ru', {
                                            style: 'currency',
                                            currency: 'RUB',
                                            maximumFractionDigits: 0
                                        })}</TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box> : <div/>
            }

        </React.Fragment>
    )
}