import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import {useState} from "react";
import {Typography} from "@mui/material";
import {Selector} from "./Selector";
import {Option} from "./Option";
import {v4 as uuidv4} from 'uuid';
import {ChartMarketStructure} from "./ChartMarketStructure";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

export const MarketStructure = (props) => {
    const [options, setOptions] = useState([]);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [awp, setAwp] = useState(true);

    const addOption = (option) => {
        const newOptions = [...options];
        option.id = uuidv4();
        newOptions.push(option);
        setOptions(newOptions);
    }

    const deleteOption = (option) => {
        const newOptions = options.filter(o => o.id !== option.id);
        setOptions(newOptions);
    }

    return (
        <Box sx={{width: '100%'}}>
            <Stack spacing={2}>
                <Item>
                    <Typography variant="h5" component='h5'>Структура рынка</Typography>
                    <Selector addOption={(option) => addOption(option)} setTo={setTo} setFrom={setFrom} to={to} from={from} awp={awp} setAwp={setAwp}/>
                    {
                        options.map((option) => <Option key={option.id} option={option} deleteOption={() => deleteOption(option)}/>)
                    }
                </Item>
                <Item>
                    <ChartMarketStructure options={options} from={from} to={to} awp={awp} />
                </Item>
            </Stack>
        </Box>
    )
}