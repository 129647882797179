import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import {Resource, CustomRoutes} from 'react-admin';
import {Route} from 'react-router-dom';
import {PriceLadder} from "./components/PriceLadder/PriceLadder";
import CustomLayout from "./Layout/CustomLayout";
import theme from "./Layout/theme";
import {MarketStructure} from "./components/PriceLadder/MarketStructure";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

export default () => (
    <HydraAdmin
        entrypoint={entrypoint}
        layout={CustomLayout}
        theme={theme}
    >
        <Resource name="brands" />
        <Resource name="models" />
        <Resource name="versions" />
        <Resource name="reference-books" />
        <Resource name="reference-book-items" />
        <Resource name="reports/price-ladder" />
        <Resource name="reports/market-structure" />
        <CustomRoutes>
            <Route path="/price-ladder" element={<PriceLadder />} />
            <Route path="/market-structure" element={<MarketStructure />} />
        </CustomRoutes>
    </HydraAdmin>
);