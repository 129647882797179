import * as React from 'react';
import {useGetList} from "react-admin";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {useEffect} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const SelectorVersion = (props) => {
    const {model, onChange} = props;
    const [versions, setVersions] = React.useState([]);

    useEffect(() => {
        setVersions([]);
    }, model.id);

    const {data, total, isLoading, error} = useGetList(
        'versions',
        {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'ord', order: 'ASC'},
            //filter: {isActive: true, isArchive: false, model: model.id}
            filter: {model: model.id}
        }
    );

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setVersions(value);
        onChange(data.filter(v => value.includes(v.id)));
    };

    if (error) {
        return <p>Ошибка загрузки комплектаций</p>;
    }

    return (
        <FormControl size="small" sx={{width: '100%'}}>
            <InputLabel id="version-selector-label">{isLoading ? "Загрузка списка комплектаций" : "Комплектация"}</InputLabel>
            <Select
                labelId="version-selector-label"
                id="version-checkbox"
                multiple
                value={versions}
                onChange={handleChange}
                input={<OutlinedInput size="small" label={isLoading ? "Загрузка списка комплектаций" : "Комплектация"}/>}
                renderValue={(selected) => {
                    return (
                        isLoading ? <div/> : <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value) => (
                                <Chip key={value} label={data.find(d => d.id === value)?.name}/>
                            ))}
                        </Box>
                    )
                }}
            >
                {isLoading ? [] : data.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={versions.find(v => v === item.id) !== undefined}/>
                                <ListItemText primary={item.name}/>
                            </MenuItem>
                        )
                    }
                )}
            </Select>
        </FormControl>
    )
}